import request from 'superagent'
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  $('#offcanvasRight').on('click', '.add_fields.add_service_description', function(event) {
    dirty = true

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).closest('.tab-pane').find('.service_description_rows').prepend($(this).data('fields').replace(regexp, time))

    let selected_frequency = $(this).closest('.row').find('.select-frequency option:selected').text()
    $('.tab-pane.active .service_description_row:first select[name*="frequency"]').val(selected_frequency)
    $('.tab-pane.active .service_description_row:first input[name*="area"]:first').attr('list', 'datalist_area_' + time)
    $('.tab-pane.active .service_description_row:first #datalist_area_').attr('id', 'datalist_area_' + time)
    $('.tab-pane.active .service_description_row:first input[name*="task"]:first').attr('list', 'datalist_task_' + time)
    $('.tab-pane.active .service_description_row:first #datalist_task_').attr('id', 'datalist_task_' + time)

    $(this).blur()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '#add_tasks', function(event) {
    let selected_area = $(this).closest('.row').find('.select-area option:selected').text()

    if (selected_area !== $(this).closest('.row').find('.select-area option:first').text()) {
      lockButton('#add_tasks')

      request.get('/service_description_options')
        .set('X-Requested-With', 'XMLHttpRequest')
        .accept('json')
        .query({
          area: selected_area
        })
        .then(res => {
          let tasks = res.body
          let missing_tasks = tasks
          let selected_frequency = $(this).closest('.row').find('.select-frequency option:selected').text()
          let current_tasks = []
          let current_rows = $('.tab-pane.active .service_description_row')

          for (var i = 0; i < current_rows.length; i++) {
            if ($(current_rows[i]).is(":visible")) {
              let area = $(current_rows[i]).find('input[name*="area"]:first').val()
              let task = $(current_rows[i]).find('input[name*="task"]:first').val()

              if (area && task) {
                if (area.trim().toLowerCase() == selected_area.trim().toLowerCase()) {
                  current_tasks.push(task.trim())
                }
              }
            }
          }

          if (current_tasks.length > 0) {
            missing_tasks = tasks.filter(x => !current_tasks.includes(x))
          }
          missing_tasks = missing_tasks.reverse()

          for (var i = 0; i < missing_tasks.length; i++) {
            $('.tab-pane.active .add_service_description').click()
            $('.tab-pane.active .service_description_row:first input[name*="area"]').val(selected_area)
            $('.tab-pane.active .service_description_row:first input[name*="task"]').val(missing_tasks[i])
            $('.tab-pane.active .service_description_row:first select[name*="frequency"]').val(selected_frequency)
          }

          unlockButton('#add_tasks')
        })
        .catch(err => {
          handleErrors(err, '#add_tasks')
        })
    } else {
      // TODO
      addToast('Valitse tila', 'alert')
    }
  })

  $('#offcanvasRight').on('focus', '.service_description_task', function(event) {
    let selected_area = $(this).closest('.row').find('input[name*="area"]').val()

    if (selected_area.length) {
      request.get('/service_description_options')
        .set('X-Requested-With', 'XMLHttpRequest')
        .accept('json')
        .query({
          area: selected_area
        })
        .then(res => {
          let datalist = $(this).closest('.row').find('.datalist-tasks')
          $(datalist).empty()

          for (var i = 0; i < res.body.length; i++) {
            $(datalist).append('<option value="' + res.body[i] + '">' + res.body[i] + '</option>')
          }
        })
        .catch(err => {
          handleErrors(err, null)
        })
    }
  })

  $('#offcanvasRight').on('change', '#copy_customer', function(event) {
    if ($(this).val()) {
      lockButton('#copy_customer')

      request.get('/service_description_options/copy_customer')
        .set('X-Requested-With', 'XMLHttpRequest')
        .accept('json')
        .query({
          customerId: $(this).val()
        })
        .then(res => {
          let missing_tasks = res.body
          let current_rows = $('.tab-pane.active .service_description_row')
          for (var i1 = 0; i1 < missing_tasks.length; i1++) {
            let found = false
            for (var i2 = 0; i2 < current_rows.length; i2++) {
              if ($(current_rows[i2]).is(":visible")) {
                let area = $(current_rows[i2]).find('input[name*="area"]:first').val()
                let task = $(current_rows[i2]).find('input[name*="task"]:first').val()

                if (area && task) {
                  if (area.trim().toLowerCase() == missing_tasks[i1][0].trim().toLowerCase() && task.trim().toLowerCase() == missing_tasks[i1][1].trim().toLowerCase()) {
                    found = true
                    break
                  }
                }
              }
            }

            if (!found) {
              $('.tab-pane.active .add_service_description').click()
              $('.tab-pane.active .service_description_row:first input[name*="area"]').val(missing_tasks[i1][0])
              $('.tab-pane.active .service_description_row:first input[name*="task"]').val(missing_tasks[i1][1])
              $('.tab-pane.active .service_description_row:first select[name*="frequency"]').val(missing_tasks[i1][2])
            }
          }

          $("#copy_customer").val(null).trigger('change')

          unlockButton('#copy_customer')
        })
        .catch(err => {
          handleErrors(err, '#copy_customer')
        })
    }
  })

  $('#offcanvasRight').on('click', '#create_service_description_pdf', function(event) {
    lockButton('#create_service_description_pdf')
    $('.customer_extra_data').val('create_service_description_pdf')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })
}